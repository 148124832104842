<template>
  <div class="card">
    <div class="card-header d-flex">
      <div class="header-title">
        <h5 class="card-title">Créer un patient</h5>
      </div>
    </div>
    <div class="card-body d-flex flex-column">
      <form @submit.prevent="submit">
        <div class="row">
          <div class="form-group col-12 col-lg-6">
            <label>Prénom</label>
            <input
              type="text"
              placeholder="Prénom"
              class="form-control mb-2"
              name="firstname"
              required
            />
          </div>

          <div class="form-group col-12 col-lg-6">
            <label>Nom</label>
            <input
              type="text"
              placeholder="Nom"
              class="form-control mb-2"
              name="lastname"
              required
            />
          </div>

          <div class="form-group col-12 col-lg-6">
            <label>Code</label>
            <input
              type="text"
              placeholder="Code"
              class="form-control mb-2"
              name="patient_code"
              required
            />
          </div>

          <div class="form-group col-12 col-lg-6">
            <label>Date de naissance</label>
            <input
              type="date"
              placeholder="Date de naissance"
              class="form-control mb-2"
              name="dateofbirth"
              required
            />
          </div>

          <div class="form-group col-12 col-lg-6">
            <label>Genre</label>
            <select class="form-select mb-2" name="gender" required>
              <option value=""></option>
              <option value="M">Masculin</option>
              <option value="F">Feminin</option>
            </select>
          </div>

          <div class="form-group col-12 col-lg-6">
            <label>Photo</label>
            <input
              type="file"
              accept="image/png, image/jpeg"
              placeholder="Date de naissance"
              class="form-control mb-2"
              name="photo"
            />
          </div>

          <div class="form-group col-12 col-lg-6">
            <label>Consent_pdf</label>
            <input
              type="file"
              placeholder="Date de naissance"
              class="form-control mb-2"
              name="consent_pdf"
            />
          </div>
        </div>

        <div>
          <b-button type="submit" class="btn btn-success m-2">
            Ajouter
          </b-button>
          <b-link class="btn btn-warning" to="/patient">Annuler</b-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ApiService from "../../Utils/axios";
// import { flashMessage } from "@smartweb/vue-flash-message";

export default {
  methods: {
    async submit(e) {
      const formData = new FormData(e.target);

      await new ApiService()
        .post("/api/patient", formData)
        .then((res) => {
          console.log("res", res);
          // this.$flashMessage.show({
          //   type: "success",
          //   title: "Patient added successfuly!",
          //   message: "Patient added successfuly!",
          // });
        })
        .catch((err) => {
          console.log("err", err);
          // this.$flashMessage.show({
          //   type: "error",
          //   title: "Error adding patient!",
          //   message: "Error adding patient!",
          // });
        });

      this.$router.push("/patient");
    },
  },
};
</script>

<style></style>
