<template>
  <div class="card">
    <div class="card-header d-flex">
      <div class="header-title">
        <h5 class="card-title">Modifier un utilisateur</h5>
      </div>
    </div>
    <div v-if="data" class="card-body d-flex flex-column">
      <form @submit.prevent="submit">
        <div class="row">
          <div class="form-group col-12 col-lg-6">
            <label>Email</label>
            <input
              type="text"
              placeholder="Email"
              class="form-control mb-2"
              name="email"
              v-model="data.email"
            />
          </div>

          <div class="form-group col-12 col-lg-6">
            <label>Nom</label>
            <input
              type="text"
              placeholder="Nom"
              class="form-control mb-2"
              name="name"
              v-model="data.name"
            />
          </div>

          <div class="form-group col-12 col-lg-6">
            <label>Mot de passe</label>

            <b-input-group>
              <b-input-group-prepend is-text>
                <b-form-checkbox v-model="show" switch class="mr-n2">
                </b-form-checkbox>
              </b-input-group-prepend>
              <b-form-input
                :type="show ? 'text' : 'password'"
                placeholder="Mot de passe"
                name="password"
                v-model="data.password"
              ></b-form-input>
            </b-input-group>
          </div>
        </div>

        <div class="mt-3">
          <b-button type="submit" class="btn btn-success m-2">Valider</b-button>
          <b-link class="btn btn-warning" to="/user">Quitter</b-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ApiService from "../../Utils/axios";
// import { flashMessage } from "@smartweb/vue-flash-message";

export default {
  data() {
    return {
      data: null,

      form: {
        name: null,
        email: null,
        password: null,
      },
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
    async submit(e) {
      try {
        this.form.name = this.data.name;
        this.form.email = this.data.email;
        this.form.password = this.data.password;
        await new ApiService().put(
          "/api/user/" + this.$route.params.id,
          this.form
        );
        // this.$flashMessage.show({
        //   type: "success",
        //   title: "User updated successfuly!",
        //   message: "User updated successfuly!",
        // });
      } catch (e) {
        // this.$flashMessage.show({
        //   type: "error",
        //   title: "Error updating user!",
        //   message: "Error updating uer!",
        // });
        console.log(e);
      }
    },
    async getUser() {
      try {
        const { data } = await new ApiService().get(
          "/api/user/" + this.$route.params.id
        );

        console.log("data: ", data);
        this.data = data;
      } catch (e) {
        console.log("error: ", e);
      }
    },
  },
};
</script>

<style></style>
