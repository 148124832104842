<script>
import ApiService from "../../Utils/axios.js";

export default {
  data() {
    return {};
  },

  mounted() {
    this.importSurgeries();
  },

  methods: {
    importSurgeries() {
      this.done = false;
      new ApiService()
        .get("/api/doimport")
        .then((res) => {
          this.done = true;
          console.log("res", res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<template>
  <div>
    <b-row>
      <h1>Hello</h1>
    </b-row>
  </div>
</template>
