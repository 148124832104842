<script>
import ApiService from "../../Utils/axios.js";
// import axios from "axios";
export default {
  data() {
    return {
      evaluations: [],
      done: false,
      cols: [
        {
          label: "Id",
          key: "id",
        },
        {
          label: "Title",
          key: "title",
        },
        {
          label: "Description",
          key: "desc",
        },
        {
          label: "Modifier",
          key: "action",
        },
        {
          lable: "Supprimer",
          key: "delete",
        },
      ],
      item: null,
      modalMsg: "",
    };
  },

  beforeMount() {
    this.fetchList();
  },

  methods: {
    onRemove(item) {
      this.item = item;
      this.modalMsg = item.name;
      if (confirm("Etes-vous sur de vouloir supprimer cette évaluation?")) {
        this.remove(this.item);
      }
    },
    remove(item) {
      //   console.log(item.id);
      this.item = item;
      let api = new ApiService();
      api
        .delete("/api/evaluation/" + this.item.id)
        .then((res) => {
          this.fetchList();
          this.$store.dispatch("Alert/activate", {
            text: "Evaluation supprimé avec succée",
            type: "success",
          });
        })
        .catch((err) => {
          if (err.response.data.code && err.response.data.code === "23000") {
            this.$store.dispatch("Alert/activate", {
              text: "L'évaluation ne peut pas être supprimé parce qu'elle est utilisé par d'autres entités du système",
              type: "danger",
            });
          } else {
            this.$store.dispatch("Alert/activate", {
              text: "Une erreur est survenue, veuillez réessayer ultérieurement ou contacter l'administration.",
              type: "danger",
            });
          }
        });
    },
    fetchList() {
      this.done = false;
      // axios
      new ApiService()
        .get("/api/evaluation")
        .then((res) => {
          this.evaluations = res.data.data;
          this.done = true;
          console.log("res", res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<template>
  <div>
      <b-row>
        <b-col-3 class="mb-3">
          <b-button variant="success">Ajouter</b-button>
        </b-col-3>
      </b-row>
      <b-row>
        <b-col>
          <b-table
            v-if="done"
            striped
            hover
            :items="evaluations"
            :fields="cols"
          >
            <template v-slot:cell(title)="data">{{
              data.item.checklist.title
            }}</template>
            <template v-slot:cell(desc)="data">{{
              data.item.checklist.description
            }}</template>
            <template v-slot:cell(action)="data">
              <router-link :to="'/evaluation/' + data.item.id"
                ><i class="fas fa-eye"></i
              ></router-link>
            </template>
            <template v-slot:cell(delete)="data"
              ><b-button
                @click="onRemove(data.item)"
                variant="iq-bg-danger"
                size="sm"
                ><i class="trash fas fa-trash"></i
              ></b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
  </div>
</template>
