import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createVuetify } from "vuetify";
// import FlashMessage from '@smartweb/vue-flash-message';

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import * as components from "vuetify/components";
import * as directives from "vuetify/directives";


// import { ModalPlugin } from 'bootstrap-vue';
import { BootstrapVue3 } from "bootstrap-vue-3";


// Import Bootstrap and BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";


const vuetify = createVuetify({
  components,
  directives,
});

createApp(App)
  .use(store)
  .use(router)
  .use(vuetify)
  .use(BootstrapVue3)
  // .use(FlashMessage)
  // .component('Datepicker', Datepicker)
  .mount("#app");
