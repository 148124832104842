<template>
  <v-app-bar color="info">
    <v-btn @click.stop="drawer = !drawer" icon>
      <v-icon>fas fa-bars</v-icon></v-btn
    >
    <v-toolbar-title><h3>Dynalists</h3></v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn variant="flat" @click="logout" prepend-icon="fas fa-power-off"
      >Déconnexion</v-btn
    >
  </v-app-bar>
  <v-navigation-drawer v-model="drawer">
    <v-list active-class="text-white bg-info" theme="light" nav>
      <v-list-item
        prepend-icon="fas fa-list-check"
        value="Check-listes"
        @click="$router.push('/checklist')"
      >
        Check-listes
      </v-list-item>
      <v-list-item
        prepend-icon="fas fa-gears"
        value="Operations"
        @click="$router.push('/surgery')"
        >Operations
      </v-list-item>
      <v-list-item
        @click="$router.push('/journey')"
        prepend-icon="fas fa-route"
        value="Trajets"
      >
        Trajets
      </v-list-item>
      <v-list-item
        @click="$router.push('/patient')"
        prepend-icon="fas fa-users"
        value="Patients"
        >Patients
      </v-list-item>
      <v-list-item
        prepend-icon="fas fa-user"
        value="Utilisateurs"
        @click="$router.push('/user')"
        >Utilisateurs
      </v-list-item>
      <v-list-item
        prepend-icon="fas fa-medal"
        value="Evaluations"
        @click="$router.push('/evaluation')"
        >Evaluations
      </v-list-item>
    </v-list>
  </v-navigation-drawer>

  <v-main>
    <div class="p-5">
      <!-- <FlashMessage time="1000" /> -->
      <Transition
        enter-active-class="animate__animated animate__backInRight"
        leave-active-class="animate__animated animate__backOutLeft"
        mode="out-in"
      >
        <router-view
      /></Transition>
    </div>
  </v-main>
</template>

<script>
export default {
  data: () => ({
    drawer: true,
  }),
  methods: {
    logout() {
      localStorage.removeItem("token");
      this.$router.push("/login");
    },
  },
};
</script>

<style>
.v-list-item:hover {
  background-color: rgba(110, 110, 110, 0.37) !important;
}
.v-list-item__overlay,
.v-list-item__underlay,
.v-list-item__overlay:hover,
.v-list-item__underlay:hover {
  background: transparent !important;
  background-color: transparent !important;
}
</style>
