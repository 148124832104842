<template>
  <div class="card">
    <div class="card-header d-flex">
      <div class="header-title">
        <h5 class="card-title">Creer une opération</h5>
      </div>
    </div>
    <div class="card-body d-flex flex-column">
      <form @submit.prevent="submit">
        <div v-if="done" class="form-group">
          <label>Dénomination</label>
          <input
            v-model="surgery.denomination"
            type="text"
            placeholder="Dénomination"
            class="form-control mb-2"
            id="DenominationInput1"
            name="denomination"
            required
          />
          <label>Code</label>
        </div>
        <div class="form-group">
          <input
            v-model="surgery.code"
            type="text"
            placeholder="Code"
            class="form-control"
            id="CodeInput1"
            name="code"
            required
          />
        </div>

        <div class="form-group">
          <label class=""> Description </label>
          <textarea
            v-model="surgery.summary"
            class="form-control"
            placeholder="Description"
            name="summary"
            required
          ></textarea>
        </div>

        <div class="form-group">
          <label class=""> Synonymes </label>
          <textarea
            v-model="surgery.synonyms"
            class="form-control"
            placeholder="Synonymes"
            name="synonyms"
            required
          ></textarea>
        </div>

        <div class="d-flex justify-content-between">
          <div class="form-group col-lg-6 p-2">
            <label for="PatientInput1">Ensemble des Check-listes</label>
            <div class="border rounded-3 p-2">
              <div
                class="mt-1"
                v-for="checklist of checklists"
                :key="checklist.id"
              >
                <span>
                  <input
                    type="checkbox"
                    :value="checklist.id"
                    :checked="selectedChecklists[checklist.id]"
                    @change="onSelect"
                  />
                  {{ checklist.reference + " - " + checklist.title }}</span
                >
              </div>
            </div>
          </div>
          <div class="col-lg-6 p-2">
            <label for="PatientInput1">Ordre des Check-listes</label>
            <VueDraggableNext
              v-model="sortedList"
              class="border rounded-3 p-2"
              @start="drag = true"
              @end="drag = false"
            >
              <transition-group>
                <div
                  v-for="(checklist, i) of sortedList"
                  :key="checklist.id"
                  class="draggable mt-1 rounded"
                >
                  <span class="badge bg-success">{{ ++i }}</span>
                  {{ " - " + checklist.title }}
                </div>
              </transition-group>
            </VueDraggableNext>
          </div>
        </div>
        <div class="form-group">
          <b-button type="submit" class="btn btn-success m-2">
            Ajouter
          </b-button>

          <b-link class="btn btn-warning" to="/surgery">Annuler</b-link>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import ApiService from "../../Utils/axios.js";
import { VueDraggableNext } from "vue-draggable-next";
export default {
  components: {
    VueDraggableNext,
  },

  data() {
    return {
      surgery: {},
      checklists: [],

      selectedChecklists: {},
      sortedList: [],

      form: {
        denomination: null,
        code: null,
        summary: null,
        synonyms: null,
      },

      drag: false,
    };
  },

  methods: {
    fetchList() {
      this.done = false;
      new ApiService()
        .get("/api/checklist")
        .then((res) => {
          this.checklists = res.data;
          this.done = true;
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async submit(e) {
      this.form.denomination = this.surgery.denomination;
      this.form.code = this.surgery.code;
      this.form.summary = this.surgery.summary;
      this.form.synonyms = this.surgery.synonyms;

      this.form.checklists = this.sortedList.map((e) => e.id);

      console.log(this.form);
      try {
        await new ApiService().post("/api/surgery", this.form);
        this.$router.push("/surgery");
      } catch (e) {
        console.log(e);
      }
    },
    onSelect({ target }) {
      if (target.checked) {
        this.sortedList.push(
          this.checklists.filter((e) => e.id == target.value)[0]
        );
      } else {
        this.sortedList = this.sortedList.filter((e) => e.id != target.value);
      }
    },
  },

  created() {
    this.fetchList();
  },
};
</script>

<style scoped>
.draggable:hover {
  background-color: rgb(218, 218, 218);
}
.draggable {
  cursor: pointer;
}
</style>
