<template>
  <div class="card" v-if="checklist">
    <div class="card-header d-flex">
      <div class="header-title">
        <h5 class="card-title">Evaluation</h5>
      </div>
    </div>

    <div class="card-body d-flex flex-column">
      <b-table striped hover :items="checklist.items" :fields="cols">
        <template v-slot:cell(answer)="data">
          {{ answers[data.item.id] || "Pas de réponse" }}
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import ApiService from "../../Utils/axios";
export default {
  data() {
    return {
      checklist: undefined,
      answers: {},
      cols: [
        {
          label: "Nom",
          key: "name",
        },
        {
          label: "Réponse",
          key: "answer",
        },
      ],
    };
  },
  mounted() {
    this.getEvaluation();
  },
  methods: {
    async getEvaluation() {
      try {
        const { data } = await new ApiService().get(
          "/api/evaluation/" + this.$route.params.id
        );
        this.checklist = data.data.checklist;
        let obj = {};
        data.data.answers.forEach((e) => {
          obj[e.item_id] = e.answer;
        });
        this.answers = obj;
        console.log(data);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style></style>
