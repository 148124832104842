import { createRouter, createWebHistory } from "vue-router";
import axios from "axios";
import store from "../store/index";

import PostIndex from "../components/PostIndex.vue";
import PatientIndex from "../components/patient/PatientIndex.vue";
import PatientCreate from "../components/patient/PatientCreate.vue";
import PatientEdit from "../components/patient/PatientEdit.vue";
import UserIndex from "../components/user/UserIndex.vue";
import UserCreate from "../components/user/UserCreate.vue";
import UserEdit from "../components/user/UserEdit.vue";
import ChecklistIndex from "../components/checklist/ChecklistIndex.vue";
import ChecklistShow from "../components/checklist/ChecklistShow.vue";
import JourneyIndex from "../components/journey/JourneyIndex.vue";
import JourneyCreate from "../components/journey/JourneyCreate.vue";
import JourneyEdit from "../components/journey/JourneyEdit.vue";
import EvaluationIndex from "../components/evaluation/EvaluationIndex.vue";
import EvaluationShow from "../components/evaluation/EvaluationShow.vue";
import SurgeryIndex from "../components/surgery/SurgeryIndex.vue";
import SurgeryCreate from "../components/surgery/SurgeryCreate.vue";
import SurgeryEdit from "../components/surgery/SurgeryEdit.vue";
import SurgeryImport from "../components/surgery/SurgeryImport.vue";
import Login from "@/components/Login.vue";
import LayoutVue from "@/components/Layout.vue";

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "",
    redirect: "/journey",
    component: LayoutVue,
    meta: { auth: true },
    children: [
      {
        path: "/post",
        name: "post",
        component: PostIndex,
        meta: { auth: true },
      },
      {
        path: "/checklist",
        name: "checklist",
        component: ChecklistIndex,
        meta: { auth: true },
      },
      {
        path: "/checklist/:id",
        name: "checklist.show",
        component: ChecklistShow,
        meta: { auth: true },
      },
      {
        path: "/surgery",
        name: "surgery",
        component: SurgeryIndex,
        meta: { auth: true },
      },
      {
        path: "/surgery/:id",
        name: "surgery.edit",
        component: SurgeryEdit,
        meta: { auth: true },
      },
      {
        path: "/surgery/new",
        name: "surgery.create",
        component: SurgeryCreate,
        meta: { auth: true },
      },
      {
        path: "/surgery/doimport",
        component: SurgeryImport,
        meta: { auth: true },
      },
      {
        path: "/patient",
        name: "patient",
        component: PatientIndex,
        meta: { auth: true },
      },
      {
        path: "/patient/new",
        name: "patient.create",
        component: PatientCreate,
        meta: { auth: true },
      },
      {
        path: "/patient/edit/:id",
        name: "patient.edit",
        component: PatientEdit,
        meta: { auth: true },
      },
      {
        path: "/user",
        name: "user",
        component: UserIndex,
        meta: { auth: true },
      },
      {
        path: "/user/new",
        name: "user.create",
        component: UserCreate,
        meta: { auth: true },
      },
      {
        path: "/user/edit/:id",
        name: "user.edit",
        component: UserEdit,
        meta: { auth: true },
      },
      {
        path: "/journey",
        name: "journey",
        component: JourneyIndex,
        meta: { auth: true },
      },
      {
        path: "/journey/new",
        name: "journey.create",
        component: JourneyCreate,
        meta: { auth: true },
      },
      {
        path: "/journey/edit/:id",
        name: "journey.edit",
        component: JourneyEdit,
        meta: { auth: true },
      },
      {
        path: "/evaluation",
        name: "evaluation",
        component: EvaluationIndex,
        meta: { auth: true },
      },
      {
        path: "/evaluation/:id",
        name: "evaluation.show",
        component: EvaluationShow,
        meta: { auth: true },
      },
    ],
  },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.auth) {
    const token = localStorage.getItem("token");
    if (!token) {
      next({ name: "login" });
      return;
    }
    try {
      const { data } = await axios.get(
        process.env.VUE_APP_API + "/api/session",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      localStorage.setItem("token", data.token);
      store.dispatch("Auth/setUser", data.user);
      next();
    } catch (e) {
      next({ name: "login" });
    }
  } else {
    next();
  }
});

export default router;
