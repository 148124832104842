<script>
import ApiService from "../../Utils/axios.js";

export default {
  data() {
    return {
      journeys: [],
      // done: false,
      cols: [
        {
          label: "Id",
          key: "id",
          sortable: true,
        },
        {
          label: "Date et heure",
          key: "scheduled",
          sortable: true,
        },
        {
          label: "Prénom",
          key: "firstname",
        },

        {
          label: "Nom",
          key: "lastname",
          sortable: true,
        },
        {
          label: "Intervention",
          key: "surgery",
          sortable: true,
        },
        {
          lable: "Modifier",
          key: "edit",
        },
        {
          lable: "Supprimer",
          key: "delete",
        },
      ],
      item: null,
      modalMsg: "",
    };
  },

  beforeMount() {
    this.fetchList();
  },

  methods: {
    fetchList() {
      this.done = false;
      // axios
      new ApiService()
        .get("/api/journey")
        .then((res) => {
          // this.journeys = res.data;
          res.data.data.forEach((e) => {
            this.journeys.push({
              id: e.id,
              scheduled: e.scheduledDateTime,
              firstname: e.patient.firstname,
              lastname: e.patient.lastname,
              surgery: e.surgery.denomination,
              // CheckListes: [e.forEach((cl) => {})],
            });
          });
          // this.journeys = res.data.data;
          this.done = true;
          console.log("journeys", this.journeys);
          console.log("res", res);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    onRemove(item) {
      this.item = item;
      this.modalMsg = item.name;
      if (confirm("Etes-vous sur de vouloir supprimer le séjour?")) {
        this.remove(this.item);
      }
    },
    remove(item) {
      //   console.log(item.id);
      this.item = item;
      let api = new ApiService();
      api
        .delete("/api/journey/" + this.item.id)
        .then((res) => {
          this.fetchList();
          this.$store.dispatch("Alert/activate", {
            text: "Patient supprimé avec succée",
            type: "success",
          });
          this.fetchList();
        })
        .catch((err) => {
          if (err.response.data.code && err.response.data.code === "23000") {
            this.$store.dispatch("Alert/activate", {
              text: "Le trajet ne peut pas être supprimé parce qu'il est utilisé par d'autres entités du système",
              type: "danger",
            });
          } else {
            this.$store.dispatch("Alert/activate", {
              text: "Une erreur est survenue, veuillez réessayer ultérieurement ou contacter l'administration.",
              type: "danger",
            });
          }
        });
    },
  },
};
</script>
<template>
  <div>
    <b-row>
      <b-col-3 class="mb-3">
        <router-link to="/journey/new" class="btn btn-success"
          >Ajouter
        </router-link>
      </b-col-3>
    </b-row>
    <b-row>
      <b-col>
        <b-table striped hover :items="journeys" :fields="cols">
          <template v-slot:cell(edit)="data"
            ><router-link :to="'/journey/edit/' + data.item.id"
              ><i class="fas fa-edit"></i></router-link
          ></template>
          <template v-slot:cell(delete)="data"
            ><b-button
              @click="onRemove(data.item)"
              variant="iq-bg-danger"
              size="sm"
              ><i class="trash fas fa-trash"></i
            ></b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>
