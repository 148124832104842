<script>
import ApiService from "../Utils/axios";
export default {
  data() {
    return {
      posts: [],
      done: false,
      // fields: ["Ids",  "Title", "Content", "Date", "Date"],
    };
  },

  beforeMount() {
    this.fetchList();
  },

  methods: {
    fetchList() {
      this.done = false;
      new ApiService()
        .get("/api/post")
        .then((res) => {
          this.posts = res.data.data;
          this.done = true;
          console.log(this.posts);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<template>
  <div>
    <b-row>
      <b-col>
        <b-table striped hover :items="posts"> </b-table>
      </b-col>
    </b-row>
  </div>
</template>
