<template>
  <div class="card">
    <div class="card-header d-flex">
      <div class="header-title">
        <h5 class="card-title">Modifier un trajet</h5>
      </div>
    </div>
    <div v-if="data" class="card-body d-flex flex-column">
      <form @submit.prevent="submit">
        <div class="row">
          <div class="form-group col-12 col-lg-6">
            <label>Opération</label>
            <select
              v-model="data.surgery.id"
              class="form-select mb-2"
              name="surgery_id"
            >
              <option value=""></option>
              <option v-for="s of surgery" :key="s.id" :value="s.id">
                {{ s.code + " - " + s.denomination }}
              </option>
            </select>
          </div>

          <div class="form-group col-12 col-lg-6">
            <label>Patient</label>
            <select
              v-model="data.patient.id"
              class="form-select mb-2"
              name="patient_id"
            >
              <option value=""></option>
              <option v-for="p of patient" :key="p.id" :value="p.id">
                {{ p.patient_code + " - " + p.lastname + " " + p.firstname }}
              </option>
            </select>
          </div>

          <div class="form-group col-12 col-lg-6">
            <label>Date planifiée</label>
            <Datepicker
              v-model="data.scheduledDateTime"
              name="scheduledDateTime"
              format="yyyy-MM-dd HH:mm"
              @selected="dateSelected()"
            ></Datepicker>
            <!-- <input
              type="datetime"
              placeholder="Date planifiée"
              class="form-control mb-2"
              name="scheduledDateTime"
              v-model="data.scheduledDateTime"
            /> -->
          </div>
        </div>
        <div>
          <b-button type="submit" class="btn btn-success m-2">
            Modifier
          </b-button>

          <b-link class="btn btn-warning" to="/journey">Quitter</b-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ApiService from "../../Utils/axios";
// import { flashMessage } from "@smartweb/vue-flash-message";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  components: { Datepicker },
  data() {
    return {
      data: {
        journey: null,
        surgery: [],
        patient: [],
        scheduledDateTime: null,
      },

      surgery: [],
      patient: [],

      form: {
        surgery_id: null,
        patient_id: null,
        scheduledDateTime: null,
      },
    };
  },

  mounted() {
    this.getJourney();
    this.getSurgery();
    this.getPatient();

    console.log(this.data);
  },
  methods: {
    async submit(e) {
      // const formData = new FormData(e.target);
      this.form.surgery_id = this.data.surgery.id;
      this.form.patient_id = this.data.patient.id;
      this.form.scheduledDateTime = this.data.scheduledDateTime;
      await new ApiService()
        .put(
          "/api/journey/" + this.$route.params.id,
          this.form
          // formData
        )
        .then((res) => {
          console.log("res", res);
          // this.$flashMessage.show({
          //   type: "success",
          //   title: "Journey updated successfuly!",
          //   message: "Journey updated successfuly!",
          // });
        })
        .catch((err) => {
          // this.$flashMessage.show({
          //   type: "error",
          //   title: "Error updating journey!",
          //   message: "Error updating journey!",
          // });
          console.log("err", err);
        });
      // this.$router.push("/journey");
    },
    async getSurgery() {
      const { data } = await new ApiService().get("/api/surgery");
      this.surgery = data;
    },
    async getPatient() {
      const { data } = await new ApiService().get("/api/patient");
      this.patient = data;
    },
    async getJourney() {
      const { data } = await new ApiService().get(
        "/api/journey/" + this.$route.params.id
      );
      this.data = data.data;
      // console.log("this.data", this.data);
      // console.log("data.data", data.data);
    },
  },
};
</script>

<style></style>
