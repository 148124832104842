<template>
  <div class="container w-50 mt-5">
    <b-card>
      <!-- <FlashMessage time="1000" /> -->
      <div class="text-center mb-5">
        <h2>Dynalists</h2>
      </div>
      <form @submit.prevent="login">
        <h4 class="mb-3">Connexion</h4>
        <div class="input">
          <label for="email">Email </label>
          <input
            class="form-control"
            type="text"
            name="email"
            placeholder="email@adress.com"
          />
        </div>
        <div class="input">
          <label for="password">Mot de passe</label>
          <b-input-group class="mt-3">
            <input
              class="form-control"
              :type="show ? 'text' : 'password'"
              name="password"
            />
            <b-input-group-append>
              <button type="button" class="btn" @click="show = !show">
                <i :class="show ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
              </button>
            </b-input-group-append>
          </b-input-group>
        </div>

        <button type="submit" class="mt-4 btn btn-primary" id="login_button">
          Se connecter
        </button>
        <div
          v-if="err"
          class="alert alert-danger fade show mt-5"
          role="alert"
          id="alert_1"
        >
          {{ err }}
        </div>
      </form></b-card
    >
  </div>
</template>

<script>
import axios from "axios";
// import { flashMessage } from "@smartweb/vue-flash-message";

export default {
  data() {
    return {
      show: false,
      err: "",
    };
  },
  methods: {
    async login(e) {
      const formData = new FormData(e.target);
      try {
        const { data } = await axios.post(
          process.env.VUE_APP_API + "/api/login",
          formData
        );
        localStorage.setItem("token", data.token);
        this.$store.dispatch("Auth/setUser", data.user);
        this.$router.push("/journey");
      } catch (e) {
        if (e.response) {
          this.err = e.response.message;
          // this.$flashMessage.show({
          //   type: "error",
          //   title: "Error login user!",
          //   text: e.response.data.message,
          // });
        }
        console.log(e);
      }
    },
  },
};
</script>

<style>
.input {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.input > label {
  text-align: start;
}
.input > input {
  margin-top: 6px;
  height: 38px !important;
}
/* From uiverse.io */
.btn-pers {
  position: relative;
  left: 50%;
  padding: 1em 2.5em;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 700;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  transform: translateX(-50%);
}
.btn-pers:hover {
  background-color: #198754;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff;
  transform: translate(-50%, -7px);
}
.btn-pers:active {
  transform: translate(-50%, -1px);
}
/*  */
.alternative-option {
  text-align: center;
}
.alternative-option > span {
  color: #0d6efd;
  cursor: pointer;
}
#sign_out {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
</style>
