<script>
import ApiService from "../../Utils/axios.js";
// import axios from "axios";
export default {
  data() {
    return {
      checklist: null,
      done: false,
      cols: [
        {
          label: "Id",
          key: "id",
        },
        // {
        //     label: "Position",
        //     key: "position",
        // },
        {
          label: "Title",
          key: "name",
        },
        {
          label: "Commentaire",
          key: "comment",
        },
        {
          label: "Importance",
          key: "importance",
        },
        // {
        //     lable: "Voir",
        //     key: "show",
        // },
      ],
    };
  },

  beforeMount() {
    this.fetchList();
  },

  methods: {
    fetchList() {
      this.done = false;
      // axios
      new ApiService()
        .get("/api/checklist/" + this.$route.params.id)
        .then((res) => {
          this.checklist = res.data;
          this.done = true;
          console.log("checklist", this.checklist);
          console.log("res", res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <div v-if="done">
            <b-row class="my-1">
              <b-col sm="3">
                <label for="input-default">Id:</label>
              </b-col>
              <b-col sm="8">
                <b-form-input
                  v-model="checklist.data.id"
                  id="input-default"
                  disabled
                >
                </b-form-input>
              </b-col>
            </b-row>

            <b-row class="my-1">
              <b-col sm="3">
                <label for="input-default">Intitulé</label>
              </b-col>
              <b-col sm="8">
                <b-form-input
                  v-model="checklist.data.title"
                  id="input-default"
                  disabled
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row class="my-1">
              <b-col sm="3">
                <label for="input-default">Description:</label>
              </b-col>
              <b-col sm="8">
                <b-form-input
                  v-model="checklist.data.description"
                  id="input-default"
                  disabled
                ></b-form-input>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
            :fields="cols"
            v-if="done"
            striped
            hover
            :items="checklist.data.items"
          >
            <!-- <template v-slot:cell(show)="data"
                            ><router-link :to="'checklist/' + data.item.id"
                                ><i class="fas fa-eye"></i></router-link
                        ></template> -->
          </b-table>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
