<template>
  <div class="card">
    <div class="card-header d-flex">
      <div class="header-title">
        <h5 class="card-title">Créer un trajet</h5>
      </div>
    </div>
    <div class="card-body d-flex flex-column">
      <form @submit.prevent="submit">
        <div class="row">
          <div class="form-group col-12 col-lg-6">
            <label>Opération</label>
            <select class="form-select mb-2" name="surgery_id">
              <option value=""></option>
              <option v-for="s of surgery" :key="s.id" :value="s.id">
                {{ s.code + " - " + s.denomination }}
              </option>
            </select>
          </div>

          <div class="form-group col-12 col-lg-6">
            <label>Patient</label>
            <select class="form-select mb-2" name="patient_id">
              <option value=""></option>
              <option v-for="p of patient" :key="p.id" :value="p.id">
                {{ p.patient_code + " - " + p.lastname + " " + p.firstname }}
              </option>
            </select>
          </div>

          <div class="form-group col-12 col-lg-6">
            <label>Date planifiée</label>
            <Datepicker
              v-model="date"
              name="scheduledDateTime"
              format="yyyy-MM-dd HH:mm"
              @selected="dateSelected()"
            ></Datepicker>
          </div>
        </div>
        <div>
          <b-button type="submit" class="btn btn-success m-2">
            Ajouter
          </b-button>

          <b-link class="btn btn-warning" to="/journey">Annuler</b-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ApiService from "../../Utils/axios";
// import { flashMessage } from "@smartweb/vue-flash-message";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  components: { Datepicker },
  data: () => ({
    surgery: [],
    patient: [],
    date: null,
  }),
  mounted() {
    this.getSurgery();
    this.getPatient();
  },
  methods: {
    // dateSelected(e) {
    //   console.log(this.appointment.appointmet_date);
    //   this.$nextTick(() => console.log(this.appointment.appointmet_date));
    // },
    async submit(e) {
      const formData = new FormData(e.target);

      await new ApiService()
        .post("/api/journey", formData)
        .then((res) => {
          // this.$flashMessage.show({
          //   type: "success",
          //   title: "Journey added successfuly!",
          //   message: "Journey added successfuly!",
          // });
          console.log(res);
        })
        .catch((err) => {
          // this.$flashMessage.show({
          //   type: "error",
          //   title: "Error adding journey!",
          //   message: "Error adding journey!",
          // });
          console.log(err);
        });

      this.$router.push("/journey");
    },
    async getSurgery() {
      const { data } = await new ApiService().get("/api/surgery");
      this.surgery = data;
    },
    async getPatient() {
      const { data } = await new ApiService().get("/api/patient");
      this.patient = data;
    },
  },
};
</script>

<style></style>
